<template>
  <div>
    <h1 class="mt-4 mb-4">Contacto</h1>

    <p>
      Información de contacto en caso de dudas o problemas técnicos.
    </p>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md card m-3 text-center shadow bg-light">
          <div class="card-body">
            <h5 class="card-title"><i class="far fa-envelope"></i> Correo</h5>
            <hr>
            <p>info@bemag.app</p>
          </div>
        </div>
        <div class="col-md card m-3 text-center shadow bg-light">
          <div class="card-body">
            <h5 class="card-title"><i class="fab fa-whatsapp"></i> WhatsApp</h5>
            <hr>
            <p>613 02 28 40</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    mounted() {
      this.$store.commit('setPanelLayout')
    }
  }
</script>
